import cookie from './cookie.inc.js';
import Dialog from './dialog.inc.js';
import { get, post } from 'axios';

let cartEnquiry = (name, email, message) => {
    if(name == '' || email == '') {
        throw 'Please make sure the name and e-mail boxes are filled.';
    }

    let emailRe = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if(!emailRe.test(String(email).toLowerCase())) {
        throw 'Your e-mail address does not match a standard format.  Please check.';
    }

    return post('/api/cart/enquiry', {
        name: name,
        email: email,
        message: message
    });
};

let addToCart = data => { 
    if( !('sku' in data) ) throw "Product SKU not specified!";
    if( !('qty' in data) ) data.qty = 1;
    if( !('attributes' in data) ) data.attributes = [];

    return post('/api/cart/add', data)
    .then(() => 
        new Dialog({
            content: 'Item successfully added to cart.',
            buttons: [
                {
                    class: 'btn-secondary', 
                    content: 'Continue Shopping',
                    onClick: () => window.location.reload()
                },
                {
                    class: 'btn-brand', 
                    content: 'Checkout',
                    onClick: () => window.location = '/cart'
                }
            ]
        }).open()
    )
    .catch(err => {
        if('response' in err) {
            new Dialog(err.response.data.error).open();
        } else {
            new Dialog('Sorry, we ran into a problem.  Please try e-mailing us your order and we will try to track down the fault in our website.').open();
            throw err;
        }
    });
};

let vatSwitch = currentMode => {
    let newMode;
    switch(currentMode) {
        case 'inc':
            newMode = 'ex';
            break;
        case 'ex':
            newMode = 'inc';
            break;
        default:
            throw "Invalid VAT mode specified!";
    }
    
    // Set the cookie on the client (for old section)
    cookie.set('vat_mode', newMode);

    return post('/api/cart/vatSwitch', { mode: newMode });
};

let getItemPrice = data => get('/api/cart/itemPrice', { params: data })
    .then(data => data.data);

let getItemStock = data => get('/api/cart/itemStock', { params: data })
    .then(data => data.data);

export default { cartEnquiry, addToCart, vatSwitch, getItemPrice, getItemStock };
export { cartEnquiry, addToCart, vatSwitch, getItemPrice, getItemStock };