export default {
    get: (name) => {
        // Get a cookie
        let cookie_value = "",
            current_cookie = "",
            name_expr = name + "=",
            all_cookies = document.cookie.split(';'),
            n = all_cookies.length;

        for (var i = 0; i < n; i++) {
            current_cookie = all_cookies[i].trim();
            if (current_cookie.indexOf(name_expr) === 0) {
                cookie_value = current_cookie.substring(name_expr.length, current_cookie.length);
                break;
            }
        }

        return cookie_value;
    },
    set: (name, value, days2expire = 7, path = '/') => {
        let date = new Date();
        date.setTime(date.getTime() + (days2expire * 24 * 60 * 60 * 1000));
        document.cookie = name + '=' + value + ';' + 'expires=' + date.toUTCString() + ';' + 'path=' + path + ';';
    }
}