import bootstrap from 'bootstrap.native/dist/bootstrap-native';
import cart from './modules/cart.inc';
import lazyload from './modules/lazyload.inc';
import cookieconsent from 'cookieconsent';
import baguetteBox from 'baguettebox.js';
import CartEnquiry from './modules/dialogs/cartEnquiry.inc';
import Dialog from './modules/dialog.inc';
import Vue from 'vue';

import cookie from './modules/cookie.inc';
import axios from 'axios';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
    dsn: "https://3529bcba3fc1435abc7081916bd1a24a@o462017.ingest.sentry.io/6058593",
    integrations: [ 
        new BrowserTracing(),
        new VueIntegration({
            Vue,
            logErrors: true,
            tracing: true,
            tracingOptions: {
                trackComponents: true,
                hooks: [],
                timeout: 10000
            }
        }) 
    ],
    tracesSampleRate: 0.25
});

// Cookie consent
// JT thinks this is absurd
window.cookieconsent.initialise({
    "palette": {
        "popup": {
            "background": "#edeff5",
            "text": "#838391"
        },
        "button": {
            "background": "#4b81e8"
        }
    },
    "theme": "edgeless",
    "position": "bottom-left",
    "content": {
        "message": "Our website sets a cookie to track your shopping cart while you browse, and includes third-party code that anonymously reports your visit so we can improve our website in the future.",
        "href": "/customer-information/cookie-policy"
    }
})

// Cart popup
let cartLink = document.querySelectorAll('.cartLink');
let cartOverlay = document.querySelector('#cartOverlay');

let $body = document.querySelector('body');

// Handle async update of the cart
$body
    .addEventListener('cart-update', async e => {
        // Update the cart totals
        let { data } = await axios.get('/api/cart/contents');

        // Update the summary text
        let $text = document.querySelector('.cartLink .total_items_no');
        if ($text) {
            $text.innerText = `${data.items.length} items @ £${data.subtotal} ${data.subtotal === data.subtotalIncVAT ? 'inc' : 'ex'} VAT`;
        }

        // Update the cart overlay
        cartOverlay.querySelector('ul.list-group')
            .innerHTML = `
                ${data.items.map(item => `
                    <li class="list-group-item">
                        <div class="row">
                            <div class="d-none d-sm-block col-sm-2 img">
                                <img class="thumbnail" src="${item.image}" />
                            </div>
                            <div class="col-sm-7">
                                <a href="${item.url}">${item.name}</a>
                                <p class="mb-0">
                                    <strong>Reference:</strong> ${item.sku}
                                </p>
                                ${Object.entries(item.attributes).map(([attrKey, attr]) => `
                                    <p class="mb-0">
                                        <strong>${attrKey}:</strong> ${attr}
                                    </p>
                                `).join('')}
                            </div>
                            <div class="col-sm-3 text-right">
                                Qty: ${item.quantity}
                                <br>
                                £ ${item.subtotal}
                                ${item.totalDiscount !== "0.00" ? `
                                    <br>
                                    <small>save £ ${item.totalDiscount}</small>
                                ` : ``}
                            </div>
                        </div>
                    </li>
                `).join('')}
                <li class="list-group-item">
                    <div class="row text-right">
                        <div class="col-9">
                            <strong>Subtotal</strong>
                        </div>
                        <div class="col-3">
                            £ ${data.subtotal}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row text-right">
                        <div class="col-9">
                            <strong>Postage</strong>
                        </div>
                        <div class="col-3">
                            £ ${data.postage}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row text-right">
                        <div class="col-9">
                            <strong>VAT</strong>
                        </div>
                        <div class="col-3">
                            £ ${data.vat}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row text-right">
                        <div class="col-9">
                            <strong>Total (inc VAT)</strong>
                        </div>
                        <div class="col-3">
                            £ ${data.totalIncVAT}
                        </div>
                    </div>
                </li>
            `;
    });

cartLink.forEach(element => 
    element.addEventListener('click', e => {
        e.preventDefault();

        if(cartOverlay.classList.contains('visible')) {
            cartOverlay.classList.remove('visible');
        } else {
            cartOverlay.classList.add('visible');
        }
    })
);

// Cart unpopup
cartOverlay.addEventListener('click', e => {
    if(e.target === cartOverlay || e.target == cartOverlay.querySelector('.row')) {
        cartOverlay.classList.remove('visible');
    }
});

// Cart VAT switch
let vatSwitchLinks = document.querySelectorAll('.vatSwitch');

vatSwitchLinks.forEach(vatSwitchLink => vatSwitchLink.addEventListener('click', e => {
    e.preventDefault();

    cart.vatSwitch(vatSwitchLink.getAttribute('data-current-mode'))
        .then(() => window.location.reload())
        .catch(err => {
            new Dialog('Sorry, we ran into a problem.  If you continue to experience issues, please let us know.').open();
            throw err;
        });
}))


// Cart e-mail enquiry
let enquiryForm = new CartEnquiry();
let enquiryButton = document.querySelector('#cartEnquiry');
enquiryButton.addEventListener('click', e => enquiryForm.open());

// Cart add
let addToCartButtons = document.querySelectorAll('[data-role="addToCart"]');

addToCartButtons.forEach( element => {
    element.addEventListener('click', e => {
        e.preventDefault();
        element.setAttribute('disabled', true);
        let data = {
            sku: element.getAttribute('data-sku'),
            qty: element.getAttribute('data-qty'),
            attributes: JSON.parse(element.getAttribute('data-attributes'))
        };

        try {
            cart.addToCart(data);
        } catch(err) {
            element.removeAttribute('disabled');
            console.log(err);
            new Dialog(err).open();
        }
    })
});

if (cookie.get('tracking_disabled') == 'true') {
    new Dialog(`
        Please note, as you have opted out of using cookies on our website, the website will not work as intended. 
        If you still wish to place an order, please email us at sales@bramptonframing.com.
    `).open();
}

// Run BaguetteBox on large images for popups
baguetteBox.run('.gallery');

window.optOut = function () {
    cookie.set('tracking_disabled', 'true');
    location.reload();
}