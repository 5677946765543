import Dialog from '../dialog.inc';
import template from './cartEnquiry.pug';
import cart from '../cart.inc';

export default class CartEnquiry extends Dialog {
    constructor(options) {
        super(options);

        let overrides = {
            title: 'Make an Enquiry',
            buttons: [
                {
                    content: 'Cancel',
                    class: 'btn-secondary',
                    onClick: this.boundClose
                },
                {
                    content: 'Send',
                    class: 'btn-brand',
                    onClick: this.submitEnquiry.bind(this)
                }
            ],
            content: template(),
            containerClass: 'modal-lg'
        };

        options = Object.assign(overrides, options);
        this.update(options);
    }

    submitEnquiry() {
        let nameBox = this.modal.querySelector('#customer-name');
        let emailBox = this.modal.querySelector('#customer-email');
        let messageBox = this.modal.querySelector('#customer-message');
        let submit = this.modal.querySelector('.btn-brand');

        submit.setAttribute('disabled', true);

        try {
            cart.cartEnquiry(nameBox.value, emailBox.value, messageBox.value)
            .then(() => {
                new Dialog('Your cart and message have been sent.  We will reply to you as soon as possible.').open();
                this.boundClose();
            }).catch(err => { 
                new Dialog('Sorry, we ran into a problem.  Please try e-mailing us your question directly.').open();
                throw err
            }).finally(() => submit.removeAttribute('disabled'));
        } catch(err) {
            submit.removeAttribute('disabled');
            new Dialog(err).open();
        }
    }
}